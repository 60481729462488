if (typeof Iugu !== 'undefined') {
  Iugu.setAccountID(process.env.VUE_APP_IUGU_ACCOUNT_ID)
  if (process.env.VUE_APP_BUILD_TARGET !== 'production') Iugu.setTestMode(true)
} else {
  console.warn('Iugu library is not loaded.')
  Iugu = 'undefined'
}

function _generateToken(form) {
  return new Promise((resolve, reject) => {
    if (!Iugu.createPaymentToken) {
      reject(new Error('Iugu.createPaymentToken is not available.'))
    }

    Iugu.createPaymentToken(form, (resp) => {
      if (resp.errors) {
        reject(resp.errors)
      } else {
        resolve(resp)
      }
    })
  })
}

export default {
  validateCvv(creditcard, cvv) {
    try {
      const brand = this.getBrandByCreditCardNumber(creditcard)
      return Iugu.utils.validateCVV(cvv, brand)
    } catch (error) {
      return false
    }
  },
  validateNumber(creditcard) {
    try {
      return Iugu.utils.getBrandByCreditCardNumber(creditcard)
    } catch (error) {
      return false
    }
  },
  validateExpiration(exp) {
    try {
      return Iugu.utils.validateExpirationString(exp)
    } catch (error) {
      return false
    }
  },
  async createCreditcardToken(form) {
    try {
      const token = await _generateToken(form)
      return token
    } catch (error) {
      return { errors: error }
    }
  },
  getBrandByCreditCardNumber(creditcard) {
    if (!creditcard) return false
    try {
      return Iugu.utils.getBrandByCreditCardNumber(creditcard)
    } catch (error) {
      return false
    }
  },
  validateExpirationByMonthAndYear({ month, year }) {
    try {
      return Iugu.utils.validateExpiration(month, year)
    } catch (error) {
      return false
    }
  },
  objectCreditCard({ number, month, year, firstName, lastName, cvv }) {
    try {
      return Iugu.CreditCard(number, month, year, firstName, lastName, cvv)
    } catch (error) {
      return null
    }
  },
}
